import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';

// Generate Order Data
function createData(id, domain, request, traffic, bandwidth) {
    return { id, domain, request, traffic, bandwidth,  };
}

const rows = [
    createData(0, 'www.google.com', '100,000', '100G', "140 Mbps"),
    createData(1, 'www.google.com', '100,000', '100G', "140 Mbps"),
    createData(2, 'www.google.com', '100,000', '100G', "140 Mbps"),
    createData(3, 'www.google.com', '100,000', '100G', "140 Mbps"),
    createData(4, 'www.google.com', '100,000', '100G', "140 Mbps"),
];

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function DomainList() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Title>域名排名</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>排名</TableCell>
                        <TableCell>域名</TableCell>
                        <TableCell>訪問次數</TableCell>
                        <TableCell>流量</TableCell>
                        <TableCell>頻寬</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id+1}</TableCell>
                            <TableCell>{row.domain}</TableCell>
                            <TableCell>{row.request}</TableCell>
                            <TableCell>{row.traffic}</TableCell>
                            <TableCell>{row.bandwidth}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    查看 域名列表
                </Link>
            </div>
        </React.Fragment>
    );
}
