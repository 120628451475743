import React, {useContext, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import Copyright from "./Copyright";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {default as NSDomains} from "./domain/NS";
import {default as CNAMEDomains} from "./domain/CNAME";
import Overview from "./overview/Overview";
import {AddNS, AddCNAME}from "./domain/Add";
import {ExitToApp} from "@material-ui/icons";
import UserManagement from "./admin/users/UserManagement";
import AddUser from "./admin/users/Add";
import axios from "axios";
import Edgepoint from "./admin/server/Edgepoint";
import Endpoint from "./admin/server/Endpoint";
import AddServer from "./admin/server/Add";
import Zone from "./zone/Zone";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function Dashboard(props) {
    // axios default setting
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common['Authorization'] = props.tokenCookie.token;

    const classes = useStyles();
    const [open, setOpen] = React.useState(sessionStorage.getItem('drawer') == "false" ? false : true );

    // check if token expire or not exist
    if (!props.tokenCookie.token ) {
        window.location = "/login"
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        sessionStorage.setItem('drawer', "true");
    };
    const handleDrawerClose = () => {
        setOpen(false);
        sessionStorage.setItem('drawer', "false");
    };

    const logout = () => {
        props.removeToken("token")
        window.location = "/login"
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        CloudSpeed
                    </Typography>
                    {/*<IconButton color="inherit" edge={"start"}>*/}
                    {/*    <Badge badgeContent={4} color="secondary">*/}
                    {/*        <NotificationsIcon />*/}
                    {/*    </Badge>*/}
                    {/*</IconButton>*/}
                    <IconButton edge={"end"} color={"inherit"} title={"登出"}>
                        <ExitToApp onClick={logout} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                {props.userCookie.user == "true" &&
                    <List>{secondaryListItems}</List>
                }
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                <Router>
                    <Switch>
                        {/*<Route exact path="/cdn">*/}
                        {/*    <Overview classes={classes}/>*/}
                        {/*</Route>*/}
                        <Route exact path="/cdn/ns">
                            <NSDomains tokenCookie={props.tokenCookie}/>
                        </Route>
                        <Route exact path="/cdn/ns/add">
                            <AddNS  tokenCookie={props.tokenCookie} data={{}}/>
                        </Route>
                        <Route exact path="/cdn/cname">
                            <CNAMEDomains tokenCookie={props.tokenCookie}/>
                        </Route>
                        <Route exact path="/cdn/cname/add">
                            <AddCNAME tokenCookie={props.tokenCookie} data={{}}/>
                        </Route>

                        {/*  Admin Section  */}
                        {props.userCookie.user == "true" &&
                        <React.Fragment>
                            <Route exact path="/cdn/admin/user">
                                <UserManagement tokenCookie={props.tokenCookie}/>
                            </Route>
                            <Route exact path="/cdn/admin/user/add">
                                <AddUser tokenCookie={props.tokenCookie} data={{}} />
                            </Route>
                            <Route exact path="/cdn/admin/server/edgepoint">
                                <Edgepoint data={{}} />
                            </Route>
                            <Route exact path="/cdn/admin/server/endpoint">
                                <Endpoint data={{}} />
                            </Route>
                            <Route exact path="/cdn/admin/server/add">
                                <AddServer data={{}} />
                            </Route>
                            <Route exact path="/cdn/admin/zone">
                                <Zone data={{}} />
                            </Route>
                        </React.Fragment>
                        }
                    </Switch>
                </Router>
                </Container>
                <Box pt={4}>
                    <Copyright/>
                </Box>
            </main>
        </div>
    );
}