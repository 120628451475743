import {Button, ButtonGroup, Link, makeStyles, Modal, Typography} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@material-ui/core/Paper";
import {DataGrid, GridToolbar} from "@material-ui/data-grid";
import {AddNS} from "../../domain/Add";
import React, {useContext, useEffect} from "react";
import axios from "axios";
import AddUser from "./Add";

const useStyles = makeStyles((theme)=>({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formDiv: {
        background: "white",
        maxWidth: "500px",
        margin: "0 auto",
        maxHeight: "800px",
        overflow: "scroll",
        padding: "15px",
    },
}))

export default function UserManagement(props) {
    const classes = useStyles();
    const [users, setUsers] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [data, setData] = React.useState()

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = (data) => {
        setData(data.row)
        setOpen(true)
    }

    useEffect(() => {
        axios.get("/user/", {

        })
            .then(function (response){
                setUsers(response.data)
        })
    },[])

    const handleDelete = (data) => {
        let confirm = window.confirm(`確認刪除 ${data.row.username} ?`)
        if(confirm) {
            axios.delete(process.env.REACT_APP_API_URL + '/user/' + data.row.id, {
                headers: {
                    'Authorization': props.tokenCookie.token
                }
            })
            .then(function() {
                setUsers(users.filter(user =>
                    user.id != data.row.id
                ))
            })
            .catch(function (error) {
                console.log(`${error}`)
            })
        }
    }

    const columns = [
        { field: 'username', headerName: '用戶名稱', flex: 1},
        { field: 'status', headerName: '狀態', flex: 0.5, valueFormatter: (params) => {return (params.value == "true" ? "啟用" : "停用")}},
        { field: 'admin', headerName: '管理員', flex: 0.5, valueFormatter: (params) => {return (params.value == "true" ? "是" : "否")} },
        { field: 'updated_at', headerName: '最後更新', flex: 1, valueFormatter: (params) => {return new Date(params.value).toLocaleString()}},
        { field: 'created_at', headerName: '創建日期', flex: 1, valueFormatter: (params) => {return new Date(params.value).toLocaleString()}},
        {
            field: "",
            sortable: false,
            headerName: "操作",
            disableClickEventBubbling: true,
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup variant={"contained"}>
                        <Button color={"primary"}  onClick={()=>{handleOpen(params)}}>設定</Button>
                        <Button color={"secondary"} onClick={()=>{handleDelete(params)}}>刪除</Button>
                    </ButtonGroup>
                );
            }},
    ];

    return (
        <React.Fragment>
            <Typography variant="h4" component={"h1"}>
                使用者管理
            </Typography>
            <Typography variant={"h6"} color={"primary"}>
                <Link  href={"/cdn/admin/user/add"} >添加用戶<AddCircleIcon style={{transform: "translateY(4px)"}}/></Link>
            </Typography>
            <Paper className={classes.paper}>
                <DataGrid rows={users} columns={columns} pageSize={10} checkboxSelection autoHeight={true}
                          components={{
                              Toolbar: GridToolbar,
                          }}
                />
            </Paper>
            <Modal open={open} onClose={handleClose} >
                <div className={classes.formDiv}>
                    <AddUser title={"編輯使用者"} tokenCookie={props.tokenCookie} method={"PATCH"} data={data} setOpen={setOpen}></AddUser>
                </div>
            </Modal>
        </React.Fragment>
    )
}