import React, {useEffect} from "react";
import {
    TextField,
    FormControl,
    makeStyles,
    Paper,
    Button,
    TextareaAutosize,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    withStyles,
    IconButton,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    field: {
        margin: "10px 0 15px"
    },
    headerForm: {
        '@media (min-width: 780px)': {
            padding: "0 30px"
        }
    },
    headerField: {
        minWidth: "20em"
    },
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


function AddNS(props) {
    const classes = useStyles()
    const [RootDomain, setRootDomain] = React.useState(props.data.RootDomain ?? "")
    const [Record, setRecord] = React.useState(props.data.Record ?? "")
    const [Recordtype, setRecordtype] = React.useState(props.data.Recordtype ?? "")
    const [Recordip, setRecordip] = React.useState(props.data.Recordip ?? "")
    const [Recordttl, setRecordttl] = React.useState(props.data.Recordttl ?? "")
    const [NShost, setNShost] = React.useState(props.data.NShost ?? "")
    const [CdnPort, setCdnPort] = React.useState(props.data.cdn_port ?? "")
    const [CdnSsl, setCdnSsl] = React.useState(props.data.cdn_ssl ?? "")
    const [Cdnswitch, setCdnswitch] = React.useState(props.data.Cdnswitch ?? "F")
    const [CacheTime, setCacheTime] = React.useState(props.data.CacheTime ?? 0)
    const [Host, setHost] = React.useState(props.data.Host ?? "")
    const [Header, setHeader] = React.useState(props.data.Header ?? "F")
    const [HeaderData, setHeaderData] = React.useState(props.data.Headers ?? [])
    const [AddHeader, setAddHeader] = React.useState()
    const [CORS, setCORS] = React.useState()
    const [AddHeaderValue, setAddHeaderValue] = React.useState()
    const [EdgeGroupList, setEdgeGroupList] = React.useState([])
    const [EndGroupList, setEndGroupList] = React.useState([])
    const [EdgeGroup, setEdgeGroup] = React.useState(props.data.EdgeGroup ?? "group01")
    const [EndpointGroup, setEndpointGroup] = React.useState(props.data.EndpointGroup ?? "group01")
    const [Sslkeyswitch, setSslkeyswitch] = React.useState(props.data.Sslkeyswitch ?? "F")
    const [Sslkeyfullchain, setSslkeyfullchain] = React.useState(props.data.Sslkeyfullchain ?? "")
    const [Sslprivatekey, setSslprivatekey] = React.useState(props.data.Sslprivatekey ?? "")
    const [Originport, setOriginport] = React.useState(props.data.Originport ?? "80")

    function NSFormHandler(e) {
        e.preventDefault();
        let data = {
            "RootDomain": e.target.elements.RootDomain.value,
            "Record": e.target.elements.Record.value,
            "Recordtype": e.target.elements.Recordtype.value,
            "Recordip": e.target.elements.Recordip.value,
            "Recordttl": e.target.elements.Recordttl.value,
            "NShost": e.target.elements.NShost.value,
            "Cdnswitch": e.target.elements.Cdnswitch.value,
            "cdn_port": e.target.elements.CdnPort ? e.target.elements.CdnPort.value : "",
            "CacheTime": e.target.elements.CacheTime ? parseInt(e.target.elements.CacheTime.value) : CacheTime,
            "Host": e.target.elements.Host ? e.target.elements.Host.value : Host,
            "Header": e.target.elements.Header ? e.target.elements.Header.value : Header,
            "EdgeGroup": e.target.elements.domainEdgeZone.value,
            "EndpointGroup": e.target.elements.domainEndpointZone.value,
            "Sslkeyswitch": e.target.elements.Sslkeyswitch.value,
            "Sslkeyfullchain": e.target.elements.Sslkeyfullchain.value,
            "Sslprivatekey": e.target.elements.Sslprivatekey.value,
        }
        if(e.target.elements.CdnSsl) {
            data["cdn_ssl"] = parseInt(e.target.elements.CdnSsl.value)
        }
        if(e.target.elements.Originport) {
            data["Originport"] = e.target.elements.Originport.value
        }

        //更新NS
        if (props.method == "PATCH") {
            axios.patch(process.env.REACT_APP_API_URL + '/ns/' + props.data.id,
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    },
                }
            ).then(function (resp) {
                    HeaderData.forEach(data => {
                        if (data.id == "新增") {
                            axios.post(process.env.REACT_APP_API_URL + '/header',
                                {
                                    "ns_id": props.data.id,
                                    "key": data.key,
                                    "value": data.value
                                }
                            )
                        } else if (data.delete) {
                            axios.delete(process.env.REACT_APP_API_URL + '/header/' + data.id)
                        }
                    })
                }
            ).then(function (resp) {
                window.alert('更新成功');
                props.setOpen(false);
                window.location.reload()
            })
        } else {
            //新增NS
            axios.post(process.env.REACT_APP_API_URL + '/ns',
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    },
                }
            ).then(function (resp) {
                if (HeaderData && HeaderData.length != 0) {
                    let id = parseInt(resp.data.id)
                    HeaderData.map(data => {
                        axios.post(process.env.REACT_APP_API_URL + '/header',
                            {
                                "ns_id": id,
                                "key": data.key,
                                "value": data.value
                            }
                        )
                    })
                }
            }).then(function (resp) {
                let answer = window.confirm('添加成功 是否繼續添加？');
                if (!answer) {
                    window.location = "/cdn/ns"
                }
            })
        }
    }

    const addCORS = () => {
        if (CORS) {
            if (!HeaderData.find(data => data.key== "Access-Control-Allow-Origin" || data.key == "Access-Control-Allow-Methods" || data.key == "Access-Control-Allow-Headers")) {
                let origin = {"id": "新增", "key": "Access-Control-Allow-Origin", "value": CORS}
                let methods = {"id": "新增", "key": "Access-Control-Allow-Methods", "value": "*"}
                let headers = {"id": "新增", "key": "Access-Control-Allow-Headers", "value": "*"}
                setHeaderData(HeaderData.concat([origin, methods, headers]))
            }
        }
    }

    const addHeader = () => {
        if (AddHeader && AddHeaderValue) {
            if (!HeaderData.find(data => data.key == AddHeader)) {
                setHeaderData(HeaderData.concat([{"id": "新增", "key": AddHeader, "value": AddHeaderValue}]))
            }
        }
    }
    const deleteHeader = (header) => {
        let target = HeaderData.find(data => data.key == header)
        if (target.id == "新增") {
            setHeaderData(HeaderData.filter(data => data.key != header))
        } else {
            target.delete = true
            setHeaderData(Object.values(HeaderData))
        }
    }

    useEffect(() =>{
        axios.get("edgepoint_group")
            .then(function(resp){
                setEdgeGroupList(resp.data)
        })
        axios.get("endpoint_group")
            .then(function(resp){
                setEndGroupList(resp.data)
            })
    }, [])

    useEffect(() => {
        HeaderData.forEach(data => {
            if ('delete' in data) {
                data.delete = null
            }
        })
        setHeaderData(Object.values(HeaderData))
    }, [props.open]);

    return (
        <React.Fragment>
            <form onSubmit={NSFormHandler}>
                <Typography variant={"h5"} component={"h1"}
                            gutterBottom={true}>{props.title ?? "新增 NS託管域名"}</Typography>
                <Typography variant={"h6"}>基本資訊</Typography>
                <Paper className={classes.paper}>
                    <FormControl fullWidth={true}>
                        <TextField label="根域名" placeholder={"ex. google.com or example.com"} className={classes.field} required={true}
                                   name={"RootDomain"} value={RootDomain}
                                   onChange={(e) => setRootDomain(e.target.value)}/>
                        <TextField label="記錄名稱" placeholder={"ex. @ or www"} className={classes.field} name={"Record"} required={true}
                                   value={Record} onChange={(e) => setRecord(e.target.value)}/>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">類型</InputLabel>
                            <Select
                                required={true}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={Recordtype ?? ""}
                                name={"Recordtype"}
                                onChange={(e) => {
                                    setRecordtype(e.target.value)
                                }}
                            >
                                <MenuItem value="A"><em>A</em></MenuItem>
                                <MenuItem value="CNAME"><em>CNAME</em></MenuItem>
                                <MenuItem value="TXT"><em>TXT</em></MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="值" placeholder={"ex. 1.1.1.1 or cdn.google.com"} required={true}
                                   className={classes.field} name={"Recordip"} value={Recordip}
                                   onChange={(e) => setRecordip(e.target.value)}/>
                        <TextField label="TTL(秒)" placeholder={"ex. 3600"} className={classes.field} name={"Recordttl"} required={true}
                                   value={Recordttl} onChange={(e) => setRecordttl(e.target.value)}/>
                        <TextField label="NameServer" placeholder={"ex. ns1.google.com, ns2.google.com"} required={true}
                                   className={classes.field} name={"NShost"} value={NShost}
                                   onChange={(e) => setNShost(e.target.value)}/>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">落地點群組</InputLabel>
                            <Select
                                required={true}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={EdgeGroup ?? ""}
                                name={"domainEdgeZone"}
                                onChange={(e) => {
                                    setEdgeGroup(e.target.value)
                                }}
                            >
                                {EdgeGroupList.map((e)=><MenuItem value={e.group_name}><em>{e.group_name}</em></MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">接口群組</InputLabel>
                            <Select
                                required={true}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={EndpointGroup ?? ""}
                                name={"domainEndpointZone"}
                                onChange={(e) => {
                                    setEndpointGroup(e.target.value)
                                }}
                            >
                                {EndGroupList.map((e)=><MenuItem value={e.group_name}><em>{e.group_name}</em></MenuItem>)}
                            </Select>
                        </FormControl>
                    </FormControl>
                </Paper>
                <Typography variant={"h6"}>CDN設定</Typography>
                <Paper className={classes.paper}>
                    <FormControl margin={"normal"}>
                        <InputLabel id="demo-simple-select-helper-label">CDN</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={Cdnswitch}
                            name={"Cdnswitch"}
                            onChange={(e) => {
                                setCdnswitch(e.target.value)
                            }}
                        >
                            <MenuItem value="F"><em>停用</em></MenuItem>
                            <MenuItem value={"T"}>啟用</MenuItem>
                        </Select>
                    </FormControl>

                    {
                        Cdnswitch == "T" &&
                        <div>
                            <TextField label="訪問端口" className={classes.field} name={"CdnPort"}
                                       value={CdnPort ?? ""} fullWidth={true}
                                       placeholder={"留空為default端口 ex. 80 or 443"}
                                       onChange={(e) => setCdnPort(e.target.value)}/>
                            <FormControl margin={"normal"} fullWidth={true}>
                                <InputLabel id="demo-simple-select-helper-label">訪問加密</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={CdnSsl}
                                    name={"CdnSsl"}
                                    onChange={(e) => {
                                        setCdnSsl(e.target.value)
                                    }}
                                >
                                    <MenuItem value={0}><em>不加密(http)</em></MenuItem>
                                    <MenuItem value={1}>加密(https)</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField label="源站端口" className={classes.field} name={"Originport"}
                                       value={Originport ?? ""} fullWidth={true}
                                       placeholder={"ex. 80, 443 或 其它Port"}
                                       onChange={(e) => setOriginport(e.target.value)}/>
                            <TextField label="緩存時間" className={classes.field} name={"CacheTime"} value={CacheTime ?? ""}
                                       fullWidth={true}
                                       placeholder={"ex. 0, 3600, 86400 單位(second)"}
                                       helperText={"不緩存可留空或0"}
                                       onChange={(e) => setCacheTime(e.target.value)}/>
                            <TextField label="源站Host" className={classes.field} name={"Host"} value={Host ?? ""}
                                       fullWidth={true}
                                       placeholder={"ex. www.example.com"}
                                       onChange={(e) => setHost(e.target.value)}/>
                            <FormControl margin={"normal"} fullWidth={true}>
                                <InputLabel id="demo-simple-select-helper-label">Response Header設定</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={Header}
                                    name={"Header"}
                                    onChange={(e) => {
                                        setHeader(e.target.value)
                                    }}
                                >
                                    <MenuItem value="F"><em>停用</em></MenuItem>
                                    <MenuItem value="T">啟用</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }
                    <FormControl className={classes.headerForm}>
                        {
                            Cdnswitch == "T" && Header == "T" &&
                            <div>
                                <TextField label="CORS 快速設定" className={classes.field}
                                           helperText="Access-Control-Allow-Origin, Methods, Headers"
                                           placeholder={"* (wildcard) or www.something.com"}
                                           className={classes.headerField}
                                           onChange={(e) => setCORS(e.target.value)}
                                />
                                <Button color="primary" type={"button"}
                                        onClick={addCORS}
                                >添加</Button>
                            </div>
                        }
                        {
                            Cdnswitch == "T" && Header == "T" &&
                            <div>
                                <Typography>自訂義Header</Typography>
                                <TextField
                                    label="Header"
                                    className={classes.headerField}
                                    style={{"marginRight": "15px"}}
                                    value={AddHeader}
                                    onChange={(e) => setAddHeader(e.target.value)}
                                />
                                <TextField
                                    label="Value"
                                    className={classes.headerField}
                                    value={AddHeaderValue}
                                    onChange={(e) => setAddHeaderValue(e.target.value)}
                                />
                                <Button
                                    color="primary" type={"button"}
                                    onClick={addHeader}
                                >添加</Button>

                                <TableContainer component={Paper} style={{"marginTop": "20px"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">ID</StyledTableCell>
                                                <StyledTableCell align="left">Header</StyledTableCell>
                                                <StyledTableCell align="left">Value</StyledTableCell>
                                                <StyledTableCell align="left">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {HeaderData.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        align="center">{row.delete ? "刪除" : row.id}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.key}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.value}</StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <IconButton aria-label="delete" onClick={() => {
                                                            deleteHeader(row.key)
                                                        }}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </FormControl>
                </Paper>
                <Typography variant={"h6"}>SSL憑證</Typography>
                <Paper className={classes.paper}>
                    <FormControl margin={"normal"} fullWidth={true}>
                        <InputLabel id="demo-simple-select-helper-label">啟用自定義SSL憑證</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={Sslkeyswitch}
                            name={"Sslkeyswitch"}
                            onChange={(e) => {
                                setSslkeyswitch(e.target.value)
                            }}
                        >
                            <MenuItem value="F"><em>停用</em></MenuItem>
                            <MenuItem value="T">啟用</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant={"body1"} gutterBottom={true}>自定義SSL憑證，若無可留空</Typography>
                    <TextareaAutosize style={{marginBottom: "10px"}} rowsMin={10} aria-label="Fullchain"
                                      placeholder="-----BEGIN FULLCHAIN CERTIFICATE-----" name={"Sslkeyfullchain"}
                                      value={Sslkeyfullchain} onChange={(e) => setSslkeyfullchain(e.target.value)}/>
                    <TextareaAutosize style={{marginBottom: "10px"}} rowsMin={10} aria-label="Private Key"
                                      placeholder="-----BEGIN RSA PRIVATE KEY-----" name={"Sslprivatekey"}
                                      value={Sslprivatekey} onChange={(e) => setSslprivatekey(e.target.value)}/>
                </Paper>
                <Button type={"submit"} variant={"contained"} color={"primary"}>送出</Button>
            </form>
        </React.Fragment>
    )
}

function AddCNAME(props) {
    const classes = useStyles()
    const [title, setTitle] = React.useState(props.title ?? "新增 CNAME託管域名")
    const [CNAMEdomain, setCNAMEdomain] = React.useState(props.data.CNAMEdomain ?? "")
    const [DomainOrigin, setDomainOrigin] = React.useState(props.data.DomainOrigin ?? "")
    const [CNAMEhost, setCNAMEhost] = React.useState(props.data.CNAMEhost ?? "")
    const [Host, setHost] = React.useState(props.data.Host ?? "")
    const [CacheTime, setCacheTime] = React.useState(props.data.CacheTime ?? 0)
    const [Header, setHeader] = React.useState(props.data.Header ?? "F")
    const [HeaderData, setHeaderData] = React.useState(props.data.Headers ?? [])
    const [AddHeader, setAddHeader] = React.useState()
    const [CORS, setCORS] = React.useState()
    const [AddHeaderValue, setAddHeaderValue] = React.useState()
    const [CdnPort, setCdnPort] = React.useState(props.data.cdn_port ?? "")
    const [CdnSsl, setCdnSsl] = React.useState(props.data.cdn_ssl ?? "")
    const [Originport, setOriginport] = React.useState(props.data.Originport ?? "")
    const [EndGroupList, setEndGroupList] = React.useState([])
    const [DomainEndpoint, setDomainEndpoint] = React.useState(props.data.DomainEndpoint ?? "group01")
    const [Sslkeyswitch, setSslkeyswitch] = React.useState(props.data.Sslkeyswitch ?? "F")
    const [Sslkeyfullchain, setSslkeyfullchain] = React.useState(props.data.Sslkeyfullchain ?? "")
    const [Sslprivatekey, setSslprivatekey] = React.useState(props.data.Sslprivatekey ?? "")

    function CNAMEFormHandler(e) {
        e.preventDefault();
        console.log(CdnSsl)
        let data = {
            "CNAMEdomain": e.target.elements.CNAMEdomain.value,
            "DomainOrigin": e.target.elements.DomainOrigin.value,
            "CNAMEhost": e.target.elements.CNAMEhost.value,
            "cdn_port": e.target.elements.CdnPort.value,
            "cdn_ssl": e.target.elements.CdnSsl.value ? parseInt(e.target.elements.CdnSsl.value) : CdnSsl,
            "Originport": e.target.elements.Originport.value,
            "CacheTime": e.target.elements.CacheTime ? parseInt(e.target.elements.CacheTime.value) : CacheTime,
            "Host": e.target.elements.Host ? e.target.elements.Host.value : Host,
            "Header": e.target.elements.Header ? e.target.elements.Header.value : Header,
            "DomainEndpoint": e.target.elements.DomainEndpoint.value,
            "Sslkeyswitch": e.target.elements.Sslkeyswitch.value,
            "Sslkeyfullchain": e.target.elements.Sslkeyfullchain.value,
            "Sslprivatekey": e.target.elements.Sslprivatekey.value,
        }
        if (props.method == "PATCH") {
            axios.patch(process.env.REACT_APP_API_URL + '/cname/' + props.data.id,
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    }
                }
            ).then(function (resp) {
                    HeaderData.forEach(data => {
                        if (data.id == "新增") {
                            axios.post(process.env.REACT_APP_API_URL + '/header',
                                {
                                    "cname_id": props.data.id,
                                    "key": data.key,
                                    "value": data.value
                                }
                            )
                        } else if (data.delete) {
                            axios.delete(process.env.REACT_APP_API_URL + '/header/' + data.id)
                        }
                    })
                }
            ).then(function (resp) {
                window.alert('更新成功');
                props.setOpen(false)
                window.location.reload()
            })
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/cname',
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    }
                }
            ).then(function (resp) {
                    if (HeaderData && HeaderData.length != 0) {
                        let id = parseInt(resp.data.id)
                        HeaderData.map(data => {
                            axios.post(process.env.REACT_APP_API_URL + '/header',
                                {
                                    "cname_id": id,
                                    "key": data.key,
                                    "value": data.value
                                }
                            )
                        })
                    }
                }
            ).then(function (resp) {
                let answer = window.confirm('添加成功 是否繼續添加？');
                if (!answer) {
                    window.location = "/cdn/cname"
                }
            })
        }
    }

    useEffect(() =>{
        axios.get("endpoint_group")
            .then(function(resp){
                setEndGroupList(resp.data)
            })
    }, [])

    useEffect(() => {
        HeaderData.forEach(data => {
            if ('delete' in data) {
                data.delete = null
            }
        })
        setHeaderData(Object.values(HeaderData))
    }, [props.open]);

    const addCORS = () => {
        if (CORS) {
            if (!HeaderData.find(data => data.key== "Access-Control-Allow-Origin" || data.key == "Access-Control-Allow-Methods" || data.key == "Access-Control-Allow-Headers")) {
                let origin = {"id": "新增", "key": "Access-Control-Allow-Origin", "value": CORS}
                let methods = {"id": "新增", "key": "Access-Control-Allow-Methods", "value": "*"}
                let headers = {"id": "新增", "key": "Access-Control-Allow-Headers", "value": "*"}
                setHeaderData(HeaderData.concat([origin, methods, headers]))
            }
        }
    }

    const addHeader = () => {
        if (AddHeader && AddHeaderValue) {
            if (!HeaderData.find(data => data.key == AddHeader)) {
                setHeaderData(HeaderData.concat([{"id": "新增", "key": AddHeader, "value": AddHeaderValue}]))
            }
        }
    }
    const deleteHeader = (header) => {
        let target = HeaderData.find(data => data.key == header)
        if (target.id == "新增") {
            setHeaderData(HeaderData.filter(data => data.key != header))
        } else {
            target.delete = true
            setHeaderData(Object.values(HeaderData))
        }
    }

    return (
        <React.Fragment>
            <Typography variant={"h5"} component={"h1"} gutterBottom={true}>{title}</Typography>
            <form onSubmit={CNAMEFormHandler}>
                <Typography variant={"h6"}>基本資訊</Typography>
                <Paper className={classes.paper}>
                    <TextField label="託管域名" placeholder={"ex. google.com or example.com"} className={classes.field} required={true}
                               name={"CNAMEdomain"} value={CNAMEdomain} onChange={(e) => {
                        setCNAMEdomain(e.target.value)
                    }}/>
                    <TextField label="CNAME域名" placeholder={"ex. ns01.cname.com"} className={classes.field} required={true}
                               name={"CNAMEhost"} value={CNAMEhost} onChange={(e) => {
                        setCNAMEhost(e.target.value)
                    }}/>
                    <TextField label="源站IP" placeholder={"ex. 1.1.1.1 or cdn.google.com"} className={classes.field} required={true}
                               name={"DomainOrigin"} value={DomainOrigin} onChange={(e) => {
                        setDomainOrigin(e.target.value)
                    }}/>
                    <FormControl margin={"normal"}>
                        <InputLabel id="demo-simple-select-helper-label">接口群組</InputLabel>
                        <Select
                            required={true}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={DomainEndpoint ?? ""}
                            name={"DomainEndpoint"}
                            onChange={(e) => {
                                setDomainEndpoint(e.target.value)
                            }}
                        >
                            {EndGroupList.map((e)=><MenuItem value={e.group_name}><em>{e.group_name}</em></MenuItem>)}
                        </Select>
                    </FormControl>
                </Paper>
                <Typography variant={"h6"}>CDN設定(預設啟用)</Typography>
                <Paper className={classes.paper}>
                    <div>
                        <TextField label="訪問端口" className={classes.field} name={"CdnPort"}
                                   value={CdnPort ?? ""} fullWidth={true}
                                   placeholder={"留空為default端口 ex. 80 or 443"}
                                   onChange={(e) => setCdnPort(e.target.value)}/>
                        <FormControl margin={"normal"} fullWidth={true}>
                            <InputLabel id="demo-simple-select-helper-label">訪問加密</InputLabel>
                            <Select
                                required={true}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={CdnSsl}
                                name={"CdnSsl"}
                                onChange={(e) => {
                                    setCdnSsl(e.target.value)
                                }}
                            >
                                <MenuItem value={0}><em>不加密(http)</em></MenuItem>
                                <MenuItem value={1}>加密(https)</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="源站端口" className={classes.field} name={"Originport"}
                                   value={Originport ?? ""} fullWidth={true}
                                   placeholder={"ex. 80, 443 或 其它Port"}
                                   required={true}
                                   onChange={(e) => setOriginport(e.target.value)}/>
                        <TextField label="緩存時間" className={classes.field} name={"CacheTime"} value={CacheTime ?? ""}
                                   fullWidth={true}
                                   placeholder={"ex. 0, 3600, 86400 單位(second)"}
                                   helperText={"不緩存可留空或0"}
                                   onChange={(e) => setCacheTime(e.target.value)}/>
                        <TextField label="源站Host" className={classes.field} name={"Host"} value={Host ?? ""}
                                   fullWidth={true}
                                   placeholder={"ex. www.example.com"}
                                   onChange={(e) => setHost(e.target.value)}/>
                        <FormControl margin={"normal"} fullWidth={true}>
                            <InputLabel id="demo-simple-select-helper-label">Response Header設定</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={Header}
                                name={"Header"}
                                onChange={(e) => {
                                    setHeader(e.target.value)
                                }}
                            >
                                <MenuItem value="F"><em>停用</em></MenuItem>
                                <MenuItem value="T">啟用</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <FormControl className={classes.headerForm}>
                        {
                            Header == "T" &&
                            <div>
                                <TextField label="CORS 快速設定" className={classes.field}
                                           helperText="Access-Control-Allow-Origin, Methods, Headers"
                                           placeholder={"* (wildcard) or www.something.com"}
                                           className={classes.headerField}
                                           onChange={(e) => setCORS(e.target.value)}
                                />
                                <Button color="primary" type={"button"}
                                        onClick={addCORS}
                                >添加</Button>
                            </div>
                        }
                        {
                            Header == "T" &&
                            <div>
                                <Typography>自訂義Header</Typography>
                                <TextField
                                    label="Header"
                                    className={classes.headerField}
                                    style={{"marginRight": "15px"}}
                                    value={AddHeader}
                                    onChange={(e) => setAddHeader(e.target.value)}
                                />
                                <TextField
                                    label="Value"
                                    className={classes.headerField}
                                    value={AddHeaderValue}
                                    onChange={(e) => setAddHeaderValue(e.target.value)}
                                />
                                <Button
                                    color="primary" type={"button"}
                                    onClick={addHeader}
                                >添加</Button>

                                <TableContainer component={Paper} style={{"marginTop": "20px"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">ID</StyledTableCell>
                                                <StyledTableCell align="left">Header</StyledTableCell>
                                                <StyledTableCell align="left">Value</StyledTableCell>
                                                <StyledTableCell align="left">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {HeaderData.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        align="center">{row.delete ? "刪除" : row.id}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.key}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.value}</StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <IconButton aria-label="delete" onClick={() => {
                                                            deleteHeader(row.key)
                                                        }}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </FormControl>
                </Paper>
                <Typography variant={"h6"}>SSL憑證</Typography>
                <Paper className={classes.paper}>
                    <FormControl margin={"normal"} fullWidth={true}>
                        <InputLabel id="demo-simple-select-helper-label">啟用自定義SSL憑證</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={Sslkeyswitch}
                            name={"Sslkeyswitch"}
                            onChange={(e) => {
                                setSslkeyswitch(e.target.value)
                            }}
                        >
                            <MenuItem value="F"><em>停用</em></MenuItem>
                            <MenuItem value="T">啟用</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant={"body1"} gutterBottom={true}>自定義SSL憑證，若無可留空</Typography>
                    <TextareaAutosize style={{marginBottom: "10px"}} rowsMin={10} aria-label="Fullchain"
                                      placeholder="-----BEGIN FULLCHAIN CERTIFICATE-----" name={"Sslkeyfullchain"}
                                      value={Sslkeyfullchain} onChange={(e) => setSslkeyfullchain(e.target.value)}/>
                    <TextareaAutosize style={{marginBottom: "10px"}} rowsMin={10} aria-label="Private Key"
                                      placeholder="-----BEGIN RSA PRIVATE KEY-----" name={"Sslprivatekey"}
                                      value={Sslprivatekey} onChange={(e) => setSslprivatekey(e.target.value)}/>
                </Paper>
                <Button type={"submit"} variant={"contained"} color={"primary"}>送出</Button>
            </form>
        </React.Fragment>
    )
}

export {AddNS, AddCNAME}