import React from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import {
    Button,
    FormControl,
    InputLabel,
    makeStyles, MenuItem,
    Paper,
    Select,
    Checkbox,
    TextField,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    TextField: {
        margin: "10px 0 15px"
    }
}))

export default function AddUser(props) {
    const classes = useStyles()
    const [title, setTitle] = React.useState(props.title ?? "新增使用者")
    const [username, setUsername] = React.useState(props.data.username ?? "")
    const [password, setPassword] = React.useState("")
    const [status, setStatus] = React.useState(props.data.status ?? true)
    const [admin, setAdmin] = React.useState(props.data.admin ?? false)

    function FormHAndler(e) {
        e.preventDefault();
        let data = {
            "username": e.target.elements.username.value,
            "password": e.target.elements.password.value,
            "status": e.target.elements.status.value,
            "admin": e.target.elements.admin.value,
        }
        if(props.method == "PATCH") {
            axios.patch(process.env.REACT_APP_API_URL + '/user/'+props.data.id,
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    }
                }
            ).then(function (resp) {
                window.alert('更新成功');
                props.setOpen(false)
                window.location.reload()
            })
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/user',
                data,
                {
                    headers: {
                        'Authorization': props.tokenCookie.token
                    }
                }
            ).then(function (resp) {
                let answer = window.confirm('添加成功 是否繼續添加？');
                if (!answer) {
                    window.location = "/cdn/admin/user"
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Typography variant={"h5"} component={"h1"} gutterBottom={true}>{title}</Typography>
            <Paper className={classes.paper}>
                <form onSubmit={FormHAndler} >
                    <FormControl fullWidth={true}>
                        <TextField label="帳號" className={classes.TextField} name={"username"} value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
                        <TextField label="密碼" className={classes.TextField} name={"password"}  value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">狀態</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={status}
                                name={"status"}
                                onChange={(e)=>{setStatus(e.target.value)
                                }}
                            >
                                <MenuItem value={false}><em>停用</em></MenuItem>
                                <MenuItem value={true}>啟用</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin={"normal"}>
                            <InputLabel id="admin-label">管理員</InputLabel>
                            <Select
                                labelId="admin-label"
                                id="admin-select-helper"
                                value={admin}
                                name={"admin"}
                                onChange={(e)=>{setAdmin(e.target.value)
                                }}
                            >
                                <MenuItem value={false}><em>否</em></MenuItem>
                                <MenuItem value={true}>是</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type={"submit"} variant={"contained"} color={"primary"}>送出</Button>
                    </FormControl>
                </form>
            </Paper>
        </React.Fragment>
    )
}