// import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import React from "react";
import Login from "./login/Login";
import Dashboard from "./Dashboard";
import {useCookies} from "react-cookie";

function App() {
    const [admin, setAdmin] = React.useState(false);
    const MyContext = React.createContext(admin);
    const [tokenCookie, setToken, removeToken] = useCookies(['token']);
    const [userCookie, setUserCookie, removeUserCookie] = useCookies(['user']);
    return (
        <Router>
            <Switch>
                <Route exact path="/login">
                    <Login setUserCookie={setUserCookie} setToken={setToken}/>
                </Route>
                <Route path="/">
                    <Dashboard tokenCookie={tokenCookie} removeToken={removeToken} userCookie={userCookie} />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
