import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "./Chart";
import Summary from "./Summary";
import DomainList from "./DomainList";
import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    fixedHeight: {
        height: 240,
    },
})

export default function Overview(prop) {
    const classes = prop.classes;
    const fixedHeightPaper = clsx(classes.paper, useStyles().fixedHeight);
    return (
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper className={fixedHeightPaper}>
                        <Chart/>
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <Summary/>
                    </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <DomainList/>
                    </Paper>
                </Grid>
            </Grid>
    )
}