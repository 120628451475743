import Paper from "@material-ui/core/Paper";
import {
    Typography,
    ButtonGroup,
    Button,
    makeStyles,
    Link,
    Modal,
} from "@material-ui/core";
import React, {useEffect} from 'react';
import {DataGrid, GridToolbar} from '@material-ui/data-grid';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import axios from "axios";
import {AddNS} from "./Add";


const useStyles = makeStyles((theme)=>({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        minWidth: 1000
    },
    formDiv: {
        background: "white",
        maxWidth: "500px",
        margin: "0 auto",
        maxHeight: "800px",
        overflow: "scroll",
        padding: "15px",
    },
}))

export default function NS(props) {
    const classes = useStyles();
    const [domain, setDomain] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [data, setData] = React.useState()

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = (data) => {
        setData(data.row)
        setOpen(true)
    }

    const handleDelete = (data) => {
        let confirm = window.confirm(`確認刪除  ${data.row.Record}.${data.row.RootDomain} ?`)

        if(confirm) {
            axios.delete(process.env.REACT_APP_API_URL + '/ns/' + data.row.id, {
                headers: {
                    'Authorization': props.tokenCookie.token
                }
            })
                .then(window.location.reload())
                .catch(function (error) {
                    console.log(`${error}`)
                })
        }
    }

    const handlePurge = (params) => {
        let fqdn = params.row.Record == '@' ? `${params.row.RootDomain}` : `${params.row.Record}.${params.row.RootDomain}`
        fqdn +=  params.row.cdn_port ? `:${params.row.cdn_port}` : "";
        axios.post(process.env.REACT_APP_API_URL + '/purge', {
            "fqdn": fqdn
        }).then(function(resp){
            let id = params.id
            domain.find(a=>a.id==id).disable = true
            setDomain(domain.concat([]))
        })
    }

    useEffect(() => {
        getNSDomain(props.tokenCookie.token, setDomain);
    }, [])

    const columns = [
        { field: 'RootDomain', headerName: '根域名', flex: 1},
        { field: 'Record', headerName: '記錄名稱', flex: 1, editable:true},
        { field: 'Recordip', headerName: '值', flex: 1 },
        { field: 'EdgeGroup', headerName: '落地點', flex: 1 },
        { field: 'EndpointGroup', headerName: '接口', flex: 1 },
        { field: 'Recordttl', headerName: 'TTL(秒)', width: 140, type: 'number'},
        {
            field: "",
            sortable: false,
            headerName: "操作",
            disableClickEventBubbling: true,
            width: 250,
            renderCell: (params) => {
                return (
                    <ButtonGroup variant={"contained"}>
                        <Button color={"primary"}  onClick={()=>{handleOpen(params)}}>設定</Button>
                        <Button color={"secondary"} onClick={()=>{handleDelete(params)}}>刪除</Button>
                        <Button color={"secondary"} variant={"outlined"} disabled={'disable' in params.row} onClick={()=>{handlePurge(params)}}>清理緩存</Button>
                    </ButtonGroup>
                );
        }},
    ];

    return (
        <React.Fragment>
            <Typography variant="h4" component={"h1"}>
                NS託管列表
            </Typography>
            <Typography variant={"h6"} color={"primary"}>
                <Link  href={"/cdn/ns/add"} >添加域名<AddCircleIcon style={{transform: "translateY(4px)"}}/></Link>
            </Typography>
            <Paper className={classes.paper}>
                <DataGrid rows={domain} columns={columns} pageSize={10} checkboxSelection autoHeight={true}
                          components={{
                              Toolbar: GridToolbar,
                          }}
                          sortModel={[{
                              field: "RootDomain",
                              sort: "asc"
                          },
                              {
                                  field: "Record",
                                  sort: "asc"
                              }
                          ]}
                />
            </Paper>
            <Modal open={open} onClose={handleClose} >
                <div className={classes.formDiv}>
                    <AddNS title={"編輯域名"} tokenCookie={props.tokenCookie} method={"PATCH"} data={data} setOpen={setOpen} open={open}></AddNS>
                </div>
            </Modal>
        </React.Fragment>
    )
}

function getNSDomain(token, setDomain) {
    axios.get(process.env.REACT_APP_API_URL + '/ns', {
        headers: {
            'Authorization': token
        }
    })
        .then(function (resp) {
            let data = resp.data
            if(resp.data){
                setDomain(data)
            }
        })
}
