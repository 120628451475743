import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Copyright from "../Copyright";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    const classes = useStyles();
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState()
    const [alertMsg, setAlertMsg] = React.useState("")
    const [rememberMe, setRememberMe] = React.useState("")

    useEffect(() => {
        if(localStorage.key("rememberMe.username")) {
            setUsername(localStorage.getItem("rememberMe.username"))
        }
    },[]);

    const signInHelper = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_API_URL+'/login', {
            username: username,
            password: password,

        })
            .then(function (response) {
                const token = response.data.token
                const expire = new Date(response.data.expire * 1000)
                props.setUserCookie("user", response.data.admin, {expires: expire})
                props.setToken("token", token, {expires: expire})
                setAlertMsg("")
                if(rememberMe) {
                    localStorage.setItem("rememberMe.username", username)
                } else {
                    localStorage.removeItem("rememberMe.username")
                }

                window.location = "/cdn/cname"
            })
            .catch(function(error){
                setAlertMsg(error.toString())
                console.log(error)
            })
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    CDN管理平台
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={username}
                        autoFocus
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" value={rememberMe} onChange={(e)=>setRememberMe(e.target.checked)} />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={signInHelper}
                    >
                        登入
                    </Button>
                    {alertMsg &&
                        <Alert severity="error">登入失敗 - {"\n"}{alertMsg}</Alert>
                    }
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}