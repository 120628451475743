import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PeopleIcon from '@material-ui/icons/People';
import DnsIcon from '@material-ui/icons/Dns';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export const mainListItems = (
    <div>
        {/*<a href={"/cdn"}>*/}
        {/*    <ListItem button>*/}
        {/*        <ListItemIcon>*/}
        {/*            <DashboardIcon/>*/}
        {/*        </ListItemIcon>*/}
        {/*        <ListItemText primary="概覽"/>*/}
        {/*    </ListItem>*/}
        {/*</a>*/}
        <a href={"/cdn/cname"}>
            <ListItem button>
                <ListItemIcon>
                    CN
                </ListItemIcon>
                <ListItemText primary="CNAME託管"/>
            </ListItem>
        </a>
        <a href={"/cdn/ns"}>
            <ListItem button>
                <ListItemIcon>
                    NS
                </ListItemIcon>
                <ListItemText primary="NS託管"/>
            </ListItem>
        </a>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Administrator</ListSubheader>
        <a href={"/cdn/admin/server/edgepoint"}>
            <ListItem button>
                <ListItemIcon>
                    <DnsIcon/>
                </ListItemIcon>
                <ListItemText primary="落地點管理"/>
            </ListItem>
        </a>
        <a href={"/cdn/admin/server/endpoint"}>
            <ListItem button>
                <ListItemIcon>
                    <DnsIcon/>
                </ListItemIcon>
                <ListItemText primary="接口管理"/>
            </ListItem>
        </a>
        <a href={"/cdn/admin/zone"}>
            <ListItem button>
                <ListItemIcon>
                    <LibraryBooksIcon/>
                </ListItemIcon>
                <ListItemText primary="區域管理"/>
            </ListItem>
        </a>
        <a href={"/cdn/admin/user"}>
            <ListItem button>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                    <ListItemText primary="用戶管理"/>
            </ListItem>
        </a>
    </div>
);