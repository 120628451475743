import {
    Button,
    ButtonGroup,
    IconButton,
    Link,
    makeStyles,
    Modal,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import axios from "axios";
import AddServer from "./Add";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formDiv: {
        background: "white",
        maxWidth: "500px",
        margin: "0 auto",
        maxHeight: "800px",
        overflow: "scroll",
        padding: "15px",
    },
}))

export default function Endpoint(props) {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState()
    const [addGroupName, setAddGroupName] = useState("")
    const [groupPage, setGroupPage] = useState(0)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = (data) => {
        setData(data.row)
        setOpen(true)
    }

    const handleDelete = function (params) {
        let confirm = window.confirm(`確認刪除主機  ${params.id} ${params.row.name} ?`)
        if(confirm) {
            axios.delete("/endpoint/" + params.row.id)
                .then(function () {
                    setRows(rows.filter(x=> x.id != params.id))
                })
                .catch(function (error) {
                    alert(error)
                })
        }
    }

    const handleGroupDelete = function (params) {
        let confirm = window.confirm(`確認刪除群組  ${params.row.group_name} ?`)
        if(confirm) {
            axios.delete("/endpoint_group/" + params.row.group_name)
                .then(function () {
                    setGroups(groups.filter(x=> x.group_name != params.row.group_name))
                })
                .catch(function (error) {
                    alert(error)
                })
        }
    }

    const handleGroupAdd = function () {
        if(addGroupName != "") {
            if(groups.findIndex(group=>group.group_name == addGroupName) == -1) {
                setGroups(groups.concat({'id': groups.length + 1, 'group_name': addGroupName, 'zone': ""}))
                setAddGroupName("")
                setGroupPage(0)
            } else {
                alert(`該名稱 ${addGroupName} 已被使用`)
            }
        }
    }

    const handleGroupSave = function(params) {
        setGroupsLoading(true)
        axios.put("/endpoint_group", {
            "group_name": params.row.group_name,
            "zone": params.row.zone
        }).then(function(resp){
            setGroupsLoading(false)
            const updatedRows = groups.map((group) => {
                if (group.id === params.id) {
                    return { ...group, "disabled": true};
                }
                return group;
            });
            setGroups(updatedRows)
        }).catch(function(error){
            alert(error)
        })
    }

    const handleGroupEditCommit = React.useCallback(
        ({ id, field, value }) => {
            if(field != "zone") return
            const updatedRows = groups.map((group) => {
                if (group.id === id && group.zone != value) {
                        group.zone = value
                        return { ...group, "disabled": false};
                    }
                return group;
            });
            setGroups(updatedRows)
        },
        [groups],
    );

    useEffect(() => {
        axios.get("/endpoint")
            .then(function (resp) {
                setRows(resp.data)
            })
        axios.get("/endpoint_group")
            .then(function (resp) {
                let data = resp.data
                for (let i = 0; i < data.length; i++) {
                    data[i].id = i + 1
                }
                setGroups(data)
            })
    }, []);

    const columns = [
        {field: 'id', headerName: 'ID', width: 100},
        {field: 'name', headerName: '名稱', flex: 1},
        {field: 'zone', headerName: '區域', width: 120},
        {field: 'ip', headerName: 'IP', width: 160},
        {
            field: 'status', headerName: '狀態', width: 100, valueFormatter: (params) => {
                return (params.value == 1 ? "啟用" : "停用")
            }
        },
        {field: 'comment', headerName: '備註', flex: 1},
        {
            field: "",
            sortable: false,
            headerName: "操作",
            disableClickEventBubbling: true,
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup variant={"contained"}>
                        <Button color={"primary"} onClick={() => {
                            handleOpen(params)
                        }}>設定</Button>
                        <Button color={"secondary"} onClick={() => {
                            handleDelete(params)
                        }}>刪除</Button>
                    </ButtonGroup>
                );
            }
        },
    ];

    const groupColumns = [
        {field: 'id', headerName: 'id', hide: true},
        {field: 'group_name', headerName: '名稱', flex: 1},
        {field: 'zone', headerName: '主機', flex: 2, editable: true},
        {
            field: "",
            sortable: false,
            headerName: "操作",
            disableClickEventBubbling: true,
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup variant={"contained"}>
                        <Button variant="contained"
                                color={"primary"}
                                disabled={params.row.disabled ?? true}
                                onClick={() => {
                                    handleGroupSave(params)
                                }}>儲存</Button>
                        <Button color={"secondary"} onClick={() => {
                            handleGroupDelete(params)
                        }}>刪除</Button>
                    </ButtonGroup>
                );
            }
        }
    ]

    return (
        <React.Fragment>
            <Typography variant="h4" component={"h1"}>
                接口
            </Typography>
            <Typography variant={"h6"} color={"primary"} gutterBottom={true}>
                <Link href={"/cdn/admin/server/add?type=end"}>添加主機<AddCircleIcon style={{transform: "translateY(4px)"}}/></Link>
            </Typography>
            <Typography variant="h5">
                主機列表
            </Typography>
            <Paper className={classes.paper}>
                <DataGrid
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight={true}
                />
            </Paper>
            <Typography variant="h5" style={{marginTop: "15px"}}>
                群組
            </Typography>
            <Typography variant={"h6"} color={"primary"} gutterBottom={true}>
                <TextField id="standard-basic" label="添加群組" placeholder="名稱" value={addGroupName} onChange={(e)=>{setAddGroupName(e.target.value)}}/>
                <IconButton color="primary" onClick={handleGroupAdd}>
                    <AddCircleIcon/>
                </IconButton>
            </Typography>
            <Typography variant={"caption"} color={"textPrimary"}>
                雙擊主機欄位開始編輯
            </Typography>
            <Paper className={classes.paper}>
                <DataGrid
                    page={groupPage}
                    onPageChange={(newPage) => setGroupPage(newPage)}
                    rows={groups}
                    columns={groupColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight={true}
                    onCellEditCommit={handleGroupEditCommit}
                    loading={groupsLoading}
                    sortModel={[{
                        field: "id",
                        sort: "desc"
                    }]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </Paper>
            <Modal open={open} onClose={handleClose} >
                <div className={classes.formDiv}>
                    <AddServer title={"編輯主機"} method={"PATCH"} type={"end"} data={data} setOpen={setOpen}></AddServer>
                </div>
            </Modal>
        </React.Fragment>
    )
}

