import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {Button, ButtonGroup, IconButton, Link, makeStyles, Paper, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}))

export default function Zone() {
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [addZone, setAddZone] = useState()
    const [page, setPage] = useState(0)

    useEffect(() => {
        setRows([])
        axios.get("/zone_group")
            .then(function (resp) {
                let data = resp.data
                for (let i = 0; i < data.length; i++) {
                    data[i].id = i + 1
                }
                setRows(data)
            })
    }, []);

    const handleZoneEditCommit = React.useCallback(
        ({ id, field, value }) => {
            if(field != "group") return
            const updatedRows = rows.map((zone) => {
                if (zone.id === id && zone.group != value) {
                    zone.group = value
                    return { ...zone, "disabled": false};
                }
                return zone;
            });
            setRows(updatedRows)
        },
        [rows],
    );

    const handleSave = function(params) {
        setLoading(true)
        axios.put("/zone_group", {
            "zone": params.row.zone,
            "group": params.row.group
        }).then(function(resp){
            setLoading(false)
            const updatedRows = rows.map((zone) => {
                if (zone.id === params.id) {
                    return { ...zone, "disabled": true};
                }
                return zone;
            });
            setRows(updatedRows)
        }).catch(function(error){
            alert(error)
        })
            .finally(()=>setLoading(false))
    }

    const handleDelete = function (params) {
        let confirm = window.confirm(`確認刪除群組  ${params.row.zone} ?`)
        if(confirm) {
            setLoading(true)
            axios.delete("/zone_group/" + params.row.zone)
                .then(function () {
                    setRows(rows.filter(x=> x.zone != params.row.zone))
                })
                .catch(function (error) {
                    alert(error)
                })
                .finally(()=>setLoading(false))
        }
    }

    const handleZoneAdd = function () {
        if(addZone != "") {
            if(rows.findIndex(zone=>zone.zone == addZone) == -1) {
                setRows(rows.concat({'id': rows.length + 1, 'zone': addZone, 'group': "", 'disabled': false}))
                setAddZone("")
                setPage(0)
            } else {
                alert(`該區域名稱 ${addZone} 已存在`)
            }
        }
    }

    const columns = [
        {field: 'id', headerName: 'id', hide: true},
        {field: "zone", headerName: "區域", width: 150},
        {field: "group", headerName:"群組", flex: 1, editable: true},
        {
            field: "",
            sortable: false,
            headerName: "操作",
            disableClickEventBubbling: true,
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup variant={"contained"}>
                        <Button variant="contained"
                                color={"primary"}
                                disabled={params.row.disabled ?? true}
                                onClick={() => {
                                    handleSave(params)
                                }}>儲存</Button>
                        <Button color={"secondary"} onClick={() => {
                            handleDelete(params)
                        }}>刪除</Button>
                    </ButtonGroup>
                );
            }
        }
    ]
    return (
        <React.Fragment>
            <Typography variant="h4" component={"h1"}>
                區域管理
            </Typography>
            <Typography variant={"h6"} color={"primary"} gutterBottom={true}>
                <TextField id="standard-basic" label="添加群組" placeholder="名稱" value={addZone} onChange={(e)=>{setAddZone(e.target.value)}}/>
                <IconButton color="primary" onClick={handleZoneAdd}>
                    <AddCircleIcon/>
                </IconButton>
            </Typography>
            <Paper className={classes.paper}>
                <DataGrid
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    sortModel={[{
                        field: "id",
                        sort: "desc"
                    }]}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoHeight={true}
                    onCellEditCommit={handleZoneEditCommit}
                    loading={loading}
                />
            </Paper>
        </React.Fragment>
    )
}