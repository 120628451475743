import React from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import {
    Button,
    FormControl,
    InputLabel,
    makeStyles, MenuItem,
    Paper,
    Select,
    Checkbox,
    TextField,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    TextField: {
        margin: "10px 0 15px"
    }
}))

export default function AddServer(props) {
    const classes = useStyles()
    const [title, setTitle] = React.useState(props.title ?? "新增主機")
    const [type, setType] = React.useState(props.type ?? (new URL(document.location)).searchParams.get("type"))
    const [name, setName] = React.useState(props.data.name)
    const [zone, setZone] = React.useState(props.data.zone)
    const [ip, setIp] = React.useState(props.data.ip)
    const [comment, setComment] = React.useState(props.data.comment)
    const [status, setStatus] = React.useState(props.data.status ?? 1)

    function FormHAndler(e) {
        e.preventDefault();
        let data = {
            "name": name,
            "zone": zone,
            "ip": ip,
            "comment": comment,
            "status": status
        }
        if(props.method == "PATCH") {
            axios.patch((type === "edge" ? '/edgepoint/' : '/endpoint/') + props.data.id, data
            ).then(function (resp) {
                window.alert('更新成功');
                props.setOpen(false)
                window.location.reload()
            })
        } else {
            axios.post(type === "edge" ? '/edgepoint' : '/endpoint', data
            ).then(function (resp) {
                console.log(resp)
                let answer = window.confirm('添加成功 是否繼續添加？');
                if (!answer) {
                    window.location = type === "edge" ? "/cdn/admin/server/edgepoint" : "/cdn/admin/server/endpoint"
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Typography variant={"h5"} component={"h1"} gutterBottom={true}>{title}</Typography>
            <Paper className={classes.paper}>
                <form onSubmit={FormHAndler} >
                    <FormControl fullWidth={true}>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">種類</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={type}
                                name={"type"}
                                disabled={props.type}
                                required={true}
                                onChange={(e)=>{setType(e.target.value)
                                }}
                            >
                                <MenuItem value={"edge"}><em>落地點</em></MenuItem>
                                <MenuItem value={"end"}>接口</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="主機名稱" className={classes.TextField} name={"name"} value={name} onChange={(e)=>{setName(e.target.value)}}/>
                        <TextField label="區域" className={classes.TextField} name={"zone"} required={true} value={zone} onChange={(e)=>{setZone(e.target.value)}}/>
                        <TextField label="IP" className={classes.TextField} name={"ip"} required={true} value={ip} onChange={(e)=>{setIp(e.target.value)}}/>
                        <FormControl margin={"normal"}>
                            <InputLabel id="demo-simple-select-helper-label">狀態</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={status}
                                name={"status"}
                                required={true}
                                onChange={(e)=>{setStatus(e.target.value)
                                }}
                            >
                                <MenuItem value={0}><em>停用</em></MenuItem>
                                <MenuItem value={1}>啟用</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="備註" className={classes.TextField} name={"comment"} value={comment} onChange={(e)=>{setComment(e.target.value)}}/>

                        <Button type={"submit"} variant={"contained"} color={"primary"}>送出</Button>
                    </FormControl>
                </form>
            </Paper>
        </React.Fragment>
    )
}